:root {
	--dt-row-selected: 13, 110, 253;
	--dt-row-selected-text: 255, 255, 255;
	--dt-row-selected-link: 9, 10, 11;
	--dt-row-stripe: 0, 0, 0;
	--dt-row-hover: 0, 0, 0;
	--dt-column-ordering: 0, 0, 0;
	--dt-html-background: white;
}

:root.dark {
	--dt-html-background: rgb(33, 37, 41);
}

input[type='checkbox']:checked::before,
.checkbox input[type='checkbox']:checked::before,
.checkbox-inline input[type='checkbox']:checked::before {
	top: -2px !important;
	left: 9px  !important;

	width: 7px !important;
	height: 14px !important;
}

table.dataTable td.dt-control:before {
	display: inline-block;
	color: rgba(0, 0, 0, 0.5);
	content: '►';
}

table.dataTable tr.dt-hasChild td.dt-control:before {
	content: '▼';
}

html.dark table.dataTable td.dt-control:before {
	color: rgba(255, 255, 255, 0.5);
}

html.dark table.dataTable tr.dt-hasChild td.dt-control:before {
	color: rgba(255, 255, 255, 0.5);
}

table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting_asc_disabled,
table.dataTable thead > tr > th.sorting_desc_disabled,
table.dataTable thead > tr > td.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting_asc_disabled,
table.dataTable thead > tr > td.sorting_desc_disabled {
	cursor: pointer;
	position: relative;
	padding-right: 26px;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
	position: absolute;
	display: block;
	opacity: 0.125;
	right: 10px;
	line-height: 9px;
	/* font-size: .8em */
	font-size: 0.6em;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:before {
	bottom: 50%;
	content: '▲';
	content: '▲' /'';
}

table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
	top: 50%;
	content: '▼';
	content: '▼' /'';
}

table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:after {
	opacity: 0.6;
}

table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before {
	display: none;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
	outline: none;
}

div.dataTables_scrollBody > table.dataTable > thead > tr > th:before,
div.dataTables_scrollBody > table.dataTable > thead > tr > th:after,
div.dataTables_scrollBody > table.dataTable > thead > tr > td:before,
div.dataTables_scrollBody > table.dataTable > thead > tr > td:after {
	display: none;
}

div.dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200px;
	margin-left: -100px;
	margin-top: -26px;
	text-align: center;
	padding: 2px;
}

div.dataTables_processing > div:last-child {
	position: relative;
	width: 80px;
	height: 15px;
	margin: 1em auto;
}

div.dataTables_processing > div:last-child > div {
	position: absolute;
	top: 0;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: rgb(13, 110, 253);
	background: rgb(var(--dt-row-selected));
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

div.dataTables_processing > div:last-child > div:nth-child(1) {
	left: 8px;
	animation: datatables-loader-1 0.6s infinite;
}

div.dataTables_processing > div:last-child > div:nth-child(2) {
	left: 8px;
	animation: datatables-loader-2 0.6s infinite;
}

div.dataTables_processing > div:last-child > div:nth-child(3) {
	left: 32px;
	animation: datatables-loader-2 0.6s infinite;
}

div.dataTables_processing > div:last-child > div:nth-child(4) {
	left: 56px;
	animation: datatables-loader-3 0.6s infinite;
}

@keyframes datatables-loader-1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes datatables-loader-3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes datatables-loader-2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(24px, 0);
	}
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
	white-space: nowrap;
}

table.dataTable th.dt-left,
table.dataTable td.dt-left {
	text-align: left;
}

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
	text-align: center;
}

table.dataTable th.dt-right,
table.dataTable td.dt-right {
	text-align: right;
}

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
	text-align: justify;
}

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
	white-space: nowrap;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
	text-align: left;
}

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
	text-align: left;
}

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
	text-align: center;
}

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
	text-align: right;
}

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
	text-align: justify;
}

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
	white-space: nowrap;
}

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
	text-align: left;
}

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
	text-align: center;
}

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
	text-align: right;
}

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
	text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
	white-space: nowrap;
}

table.dataTable {
	width: 100%;
	margin: 0 auto;
	clear: both;
	border-collapse: separate;
	border-spacing: 0;
}

table.dataTable thead th,
table.dataTable tfoot th {
	font-weight: bold;
}

table.dataTable > thead > tr > th,
table.dataTable > thead > tr > td {
	padding: 10px;
	border-bottom: 1px solid #d9d9d9;
	height: 40px;
}

table.dataTable > thead > tr > th:active,
table.dataTable > thead > tr > td:active {
	outline: none;
}

table.dataTable > tfoot > tr > th,
table.dataTable > tfoot > tr > td {
	padding: 10px 10px 6px 10px;
	border-top: 1px solid rgba(0, 0, 0, 0.3);
}

table.dataTable tbody tr {
	/* background-color: transparent */
	background-color: #ffffff;
}

table.dataTable tbody tr.selected > * {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.9);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.9);
	color: rgb(255, 255, 255);
	color: rgb(var(--dt-row-selected-text));
}

table.dataTable tbody tr.selected a {
	/* color: rgb(9, 10, 11);
    color: rgb(var(--dt-row-selected-link)) */
}

table.dataTable tbody th,
table.dataTable tbody td {
	padding: 8px 10px;
}

table.dataTable.row-border > tbody > tr > th,
table.dataTable.row-border > tbody > tr > td,
table.dataTable.display > tbody > tr > th,
table.dataTable.display > tbody > tr > td {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

table.dataTable.row-border > tbody > tr:first-child > th,
table.dataTable.row-border > tbody > tr:first-child > td,
table.dataTable.display > tbody > tr:first-child > th,
table.dataTable.display > tbody > tr:first-child > td {
	border-top: none;
}

table.dataTable.row-border > tbody > tr.selected + tr.selected > td,
table.dataTable.display > tbody > tr.selected + tr.selected > td {
	border-top-color: #0262ef;
}

table.dataTable.cell-border > tbody > tr > th,
table.dataTable.cell-border > tbody > tr > td {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

table.dataTable.cell-border > tbody > tr > th:first-child,
table.dataTable.cell-border > tbody > tr > td:first-child {
	border-left: 1px solid rgba(0, 0, 0, 0.15);
}

table.dataTable.cell-border > tbody > tr:first-child > th,
table.dataTable.cell-border > tbody > tr:first-child > td {
	border-top: none;
}

table.dataTable.stripe > tbody > tr.odd > *,
table.dataTable.display > tbody > tr.odd > * {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.023);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), 0.023);
}

table.dataTable.stripe > tbody > tr.odd.selected > *,
table.dataTable.display > tbody > tr.odd.selected > * {
	/* box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.923);
    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.923) */
	color: #000000;
	flex-direction: column;
	box-shadow: 3px 0 3px rgba(0, 0, 0, 0.07);
}

table.dataTable.hover > tbody > tr:hover > *,
table.dataTable.display > tbody > tr:hover > * {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.035);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.035);
}

table.dataTable.hover > tbody > tr.selected:hover > *,
table.dataTable.display > tbody > tr.selected:hover > * {
	box-shadow: inset 0 0 0 9999px #0d6efd !important;
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 1) !important;
}

table.dataTable.order-column > tbody tr > .sorting_1,
table.dataTable.order-column > tbody tr > .sorting_2,
table.dataTable.order-column > tbody tr > .sorting_3,
table.dataTable.display > tbody tr > .sorting_1,
table.dataTable.display > tbody tr > .sorting_2,
table.dataTable.display > tbody tr > .sorting_3 {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.019);
}

table.dataTable.order-column > tbody tr.selected > .sorting_1,
table.dataTable.order-column > tbody tr.selected > .sorting_2,
table.dataTable.order-column > tbody tr.selected > .sorting_3,
table.dataTable.display > tbody tr.selected > .sorting_1,
table.dataTable.display > tbody tr.selected > .sorting_2,
table.dataTable.display > tbody tr.selected > .sorting_3 {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
}

table.dataTable.display > tbody > tr.odd > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_1 {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.054);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.054);
}

table.dataTable.display > tbody > tr.odd > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_2 {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.047);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.047);
}

table.dataTable.display > tbody > tr.odd > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_3 {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.039);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.039);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_1 {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.954);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.954);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_2 {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.947);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.947);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_3 {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.939);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.939);
}

table.dataTable.display > tbody > tr.even > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.even > .sorting_1 {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.019);
}

table.dataTable.display > tbody > tr.even > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr.even > .sorting_2 {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.011);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.011);
}

table.dataTable.display > tbody > tr.even > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr.even > .sorting_3 {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.003);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.003);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_1 {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_2 {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.911);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.911);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_3 {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.903);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.903);
}

table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.082);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.082);
}

table.dataTable.display tbody tr:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.074);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.074);
}

table.dataTable.display tbody tr:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
	box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.062);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.062);
}

table.dataTable.display tbody tr:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.982);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.982);
}

table.dataTable.display tbody tr:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.974);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.974);
}

table.dataTable.display tbody tr:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
	box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.962);
	box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.962);
}

table.dataTable.no-footer {
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

table.dataTable.compact thead th,
table.dataTable.compact thead td,
table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td,
table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
	padding: 4px;
}

table.dataTable th,
table.dataTable td {
	box-sizing: content-box;
}

.dataTables_wrapper {
	position: relative;
	clear: both;
}

.dataTables_wrapper .dataTables_length {
	float: left;
}

.dataTables_wrapper .dataTables_length select {
	border: 1px solid #aaa;
	border-radius: 3px;
	padding: 5px;
	background-color: transparent;
	color: inherit;
	padding: 4px;
}

.dataTables_wrapper .dataTables_filter {
	float: right;
	text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
	border: 1px solid #aaa;
	border-radius: 3px;
	padding: 5px;
	background-color: transparent;
	color: inherit;
	margin-left: 3px;
}

.dataTables_wrapper .dataTables_info {
	clear: none;
	float: right;
	padding-top: 1em;
	padding-left: 47px;
}

.dataTables_wrapper .dataTables_paginate {
	float: right;
	text-align: right;
	padding-top: 0.755em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	box-sizing: border-box;
	display: inline-block;
	min-width: 1.5em;
	padding: 0.5em 1em;
	margin-left: 2px;
	text-align: center;
	text-decoration: none !important;
	cursor: pointer;
	color: inherit !important;
	border: 1px solid transparent;
	border-radius: 2px;
	background: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	color: inherit !important;
	border: 1px solid rgba(0, 0, 0, 0.3);
	background-color: rgba(0, 0, 0, 0.05);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, rgba(230, 230, 230, 0.05)),
		color-stop(100%, rgba(0, 0, 0, 0.05))
	);
	background: -webkit-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
	background: -moz-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
	background: -ms-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
	background: -o-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
	background: linear-gradient(to bottom, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
	cursor: default;
	color: #666 !important;
	border: 1px solid transparent;
	background: transparent;
	box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	/* color: white; */
	color: none;
	border: 1px solid #111;
	background-color: #111;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
	background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
	background: -moz-linear-gradient(top, #585858 0%, #111 100%);
	background: -ms-linear-gradient(top, #585858 0%, #111 100%);
	background: -o-linear-gradient(top, #585858 0%, #111 100%);
	background: linear-gradient(to bottom, #585858 0%, #111 100%);
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
	outline: none;
	background-color: #0c0c0c;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #2b2b2b),
		color-stop(100%, #0c0c0c)
	);
	background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
	background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
	background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
	background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
	background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
	box-shadow: inset 0 0 3px #111;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
	padding: 0 1em;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
	color: inherit;
}

.dataTables_wrapper .dataTables_scroll {
	clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
	-webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
	vertical-align: middle;
}

.dataTables_wrapper
	.dataTables_scroll
	div.dataTables_scrollBody
	> table
	> thead
	> tr
	> th
	> div.dataTables_sizing,
.dataTables_wrapper
	.dataTables_scroll
	div.dataTables_scrollBody
	> table
	> thead
	> tr
	> td
	> div.dataTables_sizing,
.dataTables_wrapper
	.dataTables_scroll
	div.dataTables_scrollBody
	> table
	> tbody
	> tr
	> th
	> div.dataTables_sizing,
.dataTables_wrapper
	.dataTables_scroll
	div.dataTables_scrollBody
	> table
	> tbody
	> tr
	> td
	> div.dataTables_sizing {
	height: 0;
	overflow: hidden;
	margin: 0 !important;
	padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.3) */
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
	border-bottom: none;
}

.dataTables_wrapper:after {
	visibility: hidden;
	display: block;
	content: '';
	clear: both;
	height: 0;
}

@media screen and (max-width: 767px) {
	.dataTables_wrapper .dataTables_info,
	.dataTables_wrapper .dataTables_paginate {
		float: none;
		text-align: center;
	}

	.dataTables_wrapper .dataTables_paginate {
		margin-top: 0.5em;
	}
}

@media screen and (max-width: 640px) {
	.dataTables_wrapper .dataTables_length,
	.dataTables_wrapper .dataTables_filter {
		float: none;
		text-align: center;
	}

	.dataTables_wrapper .dataTables_filter {
		margin-top: 0.5em;
	}
}

html.dark {
	--dt-row-hover: 255, 255, 255;
	--dt-row-stripe: 255, 255, 255;
	--dt-column-ordering: 255, 255, 255;
}

html.dark table.dataTable > thead > tr > th,
html.dark table.dataTable > thead > tr > td {
	border-bottom: 1px solid rgb(89, 91, 94);
}

html.dark table.dataTable > thead > tr > th:active,
html.dark table.dataTable > thead > tr > td:active {
	outline: none;
}

html.dark table.dataTable > tfoot > tr > th,
html.dark table.dataTable > tfoot > tr > td {
	border-top: 1px solid rgb(89, 91, 94);
}

html.dark table.dataTable.row-border > tbody > tr > th,
html.dark table.dataTable.row-border > tbody > tr > td,
html.dark table.dataTable.display > tbody > tr > th,
html.dark table.dataTable.display > tbody > tr > td {
	border-top: 1px solid rgb(64, 67, 70);
}

html.dark table.dataTable.row-border > tbody > tr.selected + tr.selected > td,
html.dark table.dataTable.display > tbody > tr.selected + tr.selected > td {
	border-top-color: #0257d5;
}

html.dark table.dataTable.cell-border > tbody > tr > th,
html.dark table.dataTable.cell-border > tbody > tr > td {
	border-top: 1px solid rgb(64, 67, 70);
	border-right: 1px solid rgb(64, 67, 70);
}

html.dark table.dataTable.cell-border > tbody > tr > th:first-child,
html.dark table.dataTable.cell-border > tbody > tr > td:first-child {
	border-left: 1px solid rgb(64, 67, 70);
}

html.dark .dataTables_wrapper .dataTables_filter input,
html.dark .dataTables_wrapper .dataTables_length select {
	border: 1px solid rgba(255, 255, 255, 0.2);
	background-color: var(--dt-html-background);
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.current,
html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	border: 1px solid rgb(89, 91, 94);
	background: rgba(255, 255, 255, 0.15);
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
	color: #666 !important;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	border: 1px solid rgb(53, 53, 53);
	background: rgb(53, 53, 53);
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button:active {
	background: #3a3a3a;
}

table.dataTable > tbody > tr > .selected {
	background-color: rgba(13, 110, 253, 0.9);
	color: white;
}

table.dataTable > tbody > tr > td.select-checkbox,
table.dataTable > tbody > tr > th.select-checkbox {
	position: relative;
}

table.dataTable > tbody > tr > td.select-checkbox:before,
table.dataTable > tbody > tr > td.select-checkbox:after,
table.dataTable > tbody > tr > th.select-checkbox:before,
table.dataTable > tbody > tr > th.select-checkbox:after {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 16px;
	height: 16px;
	box-sizing: border-box;
}

table.dataTable > tbody > tr > td.select-checkbox:before,
table.dataTable > tbody > tr > th.select-checkbox:before {
	content: ' ';
	margin-top: -6px;
	margin-left: -6px;
	border: 1px solid #c2c2c2;
	border-radius: 3px;
}

table.dataTable > tbody > tr.selected > td.select-checkbox:before,
table.dataTable > tbody > tr.selected > th.select-checkbox:before {
	border: 1px solid #2d8cff;
}

table.dataTable > tbody > tr.selected > td.select-checkbox:after,
table.dataTable > tbody > tr.selected > th.select-checkbox:after {
	/* content: "✓";
    font-size: 15px;
    margin-top: -9px;
    margin-left: -6px;
    text-align: center;
    font-weight: 700;
    color: #2d8cff; */
	content: '';
	position: absolute;
	/* top: 0 !important; */
	margin-top: -12px !important;
	left: 13px;
	display: table;
	width: 7px;
	height: 14px;
	border: 3px solid #2d8cff;
	border-top-width: 3px;
	border-left-width: 3px;
	border-top-width: 3px;
	border-left-width: 3px;
	border-top-width: 0;
	border-left-width: 0;
	rotate: 50deg;
}

table.dataTable.compact > tbody > tr > td.select-checkbox:before,
table.dataTable.compact > tbody > tr > th.select-checkbox:before {
	margin-top: -12px;
}

table.dataTable.compact > tbody > tr.selected > td.select-checkbox:after,
table.dataTable.compact > tbody > tr.selected > th.select-checkbox:after {
	margin-top: -16px;
}

div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
	margin-left: 0.5em;
}

html.dark table.dataTable > tbody > tr > td.select-checkbox:before,
html.dark table.dataTable > tbody > tr > th.select-checkbox:before,
html[data-bs-theme='dark'] table.dataTable > tbody > tr > td.select-checkbox:before,
html[data-bs-theme='dark'] table.dataTable > tbody > tr > th.select-checkbox:before {
	border: 1px solid rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 640px) {
	div.dataTables_wrapper span.select-info,
	div.dataTables_wrapper span.select-item {
		margin-left: 0;
		display: block;
	}
}

table.dataTable thead tr > .dtfc-fixed-left,
table.dataTable thead tr > .dtfc-fixed-right,
table.dataTable tfoot tr > .dtfc-fixed-left,
table.dataTable tfoot tr > .dtfc-fixed-right {
	top: 0;
	bottom: 0;
	z-index: 3;
	background-color: white;
}

table.dataTable tbody tr > .dtfc-fixed-left,
table.dataTable tbody tr > .dtfc-fixed-right {
	z-index: 1;
	background-color: white;
}

div.dtfc-left-top-blocker,
div.dtfc-right-top-blocker {
	/* background-color: white */
}

html.dark table.dataTable thead tr > .dtfc-fixed-left,
html.dark table.dataTable thead tr > .dtfc-fixed-right,
html.dark table.dataTable tfoot tr > .dtfc-fixed-left,
html.dark table.dataTable tfoot tr > .dtfc-fixed-right {
	background-color: var(--dt-html-background);
}

html.dark table.dataTable tbody tr > .dtfc-fixed-left,
html.dark table.dataTable tbody tr > .dtfc-fixed-right {
	background-color: var(--dt-html-background);
}

html.dark div.dtfc-left-top-blocker,
html.dark div.dtfc-right-top-blocker {
	background-color: var(--dt-html-background);
}

@keyframes dtb-spinner {
	100% {
		transform: rotate(360deg);
	}
}

@-o-keyframes dtb-spinner {
	100% {
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-ms-keyframes dtb-spinner {
	100% {
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes dtb-spinner {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes dtb-spinner {
	100% {
		-moz-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

div.dataTables_wrapper {
	position: relative;
}

div.dt-buttons {
	position: initial;
}

div.dt-buttons .dt-button {
	overflow: hidden;
	text-overflow: ellipsis;
}

div.dt-button-info {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 400px;
	margin-top: -100px;
	margin-left: -200px;
	background-color: white;
	border-radius: 0.75em;
	box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.8);
	text-align: center;
	z-index: 2003;
	overflow: hidden;
}

div.dt-button-info h2 {
	padding: 2rem 2rem 1rem 2rem;
	margin: 0;
	font-weight: normal;
}

div.dt-button-info > div {
	padding: 1em 2em 2em 2em;
}

div.dtb-popover-close {
	position: absolute;
	top: 6px;
	right: 6px;
	width: 22px;
	height: 22px;
	text-align: center;
	border-radius: 3px;
	cursor: pointer;
	z-index: 2003;
}

button.dtb-hide-drop {
	display: none !important;
}

div.dt-button-collection-title {
	text-align: center;
	padding: 0.3em 0 0.5em;
	margin-left: 0.5em;
	margin-right: 0.5em;
	font-size: 0.9em;
}

div.dt-button-collection-title:empty {
	display: none;
}

span.dt-button-spacer {
	display: inline-block;
	margin: 0.5em;
	white-space: nowrap;
}

span.dt-button-spacer.bar {
	border-left: 1px solid rgba(0, 0, 0, 0.3);
	vertical-align: middle;
	padding-left: 0.5em;
}

span.dt-button-spacer.bar:empty {
	height: 1em;
	width: 1px;
	padding-left: 0;
}

div.dt-button-collection .dt-button-active {
	padding-right: 3em;
}

div.dt-button-collection .dt-button-active:after {
	position: absolute;
	top: 50%;
	margin-top: -10px;
	right: 1em;
	display: inline-block;
	content: '✓';
	color: inherit;
}

div.dt-button-collection .dt-button-active.dt-button-split {
	padding-right: 0;
}

div.dt-button-collection .dt-button-active.dt-button-split:after {
	display: none;
}

div.dt-button-collection .dt-button-active.dt-button-split > *:first-child {
	padding-right: 3em;
}

div.dt-button-collection .dt-button-active.dt-button-split > *:first-child:after {
	position: absolute;
	top: 50%;
	margin-top: -10px;
	right: 1em;
	display: inline-block;
	content: '✓';
	color: inherit;
}

div.dt-button-collection .dt-button-active-a a {
	padding-right: 3em;
}

div.dt-button-collection .dt-button-active-a a:after {
	position: absolute;
	right: 1em;
	display: inline-block;
	content: '✓';
	color: inherit;
}

div.dt-button-collection span.dt-button-spacer {
	width: 100%;
	font-size: 0.9em;
	text-align: center;
	margin: 0.5em 0;
}

div.dt-button-collection span.dt-button-spacer:empty {
	height: 0;
	width: 100%;
}

div.dt-button-collection span.dt-button-spacer.bar {
	border-left: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-left: 0;
}

html.dark div.dt-button-info {
	background-color: var(--dt-html-background);
	border: 1px solid rgba(255, 255, 255, 0.15);
}

div.dt-buttons {
	float: right;
	margin-bottom: 5px;
}

div.dt-buttons.buttons-right {
	float: right;
}

div.dataTables_layout_cell div.dt-buttons {
	float: none;
}

div.dataTables_layout_cell div.dt-buttons.buttons-right {
	float: none;
}

div.dt-buttons > .dt-button,
div.dt-buttons > div.dt-button-split .dt-button {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	margin-left: 0.167em;
	margin-right: 0.167em;
	/* margin-bottom: .333em; */
	margin-bottom: 0px;
	padding: 0.5em 1em;
	/* border: 1px solid rgba(0, 0, 0, 0.3); */
	border: none;
	/* border-radius: 2px; */
	border-radius: 5px;
	cursor: pointer;
	font-size: 0.88em;
	line-height: 1.6em;
	color: inherit;
	white-space: nowrap;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.1);
	/* background: linear-gradient(to bottom, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%); */
	background: #f0f0f2;
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr="rgba(230, 230, 230, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-decoration: none;
	outline: none;
	text-overflow: ellipsis;
}

div.dt-buttons > .dt-button:first-child,
div.dt-buttons > div.dt-button-split .dt-button:first-child {
	margin-left: 0;
}

div.dt-buttons > .dt-button.disabled,
div.dt-buttons > div.dt-button-split .dt-button.disabled {
	cursor: default;
	opacity: 0.4;
}

div.dt-buttons > .dt-button.dt-button-active:not(.disabled),
div.dt-buttons > div.dt-button-split .dt-button.dt-button-active:not(.disabled) {
	background-color: rgba(0, 0, 0, 0.1);
	background: linear-gradient(to bottom, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr="rgba(179, 179, 179, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
	box-shadow: inset 1px 1px 3px #999;
}

div.dt-buttons > .dt-button.dt-button-active:not(.disabled):hover:not(.disabled),
div.dt-buttons > div.dt-button-split .dt-button.dt-button-active:not(.disabled):hover:not(.disabled) {
	box-shadow: inset 1px 1px 3px #999;
	background-color: rgba(0, 0, 0, 0.1);
	background: linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr="rgba(128, 128, 128, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
}

div.dt-buttons > .dt-button:hover,
div.dt-buttons > div.dt-button-split .dt-button:hover {
	text-decoration: none;
}

div.dt-buttons > .dt-button:hover:not(.disabled),
div.dt-buttons > div.dt-button-split .dt-button:hover:not(.disabled) {
	/* border: 1px solid #666; */
	border: none;
	background-color: rgba(0, 0, 0, 0.1);
	background: linear-gradient(to bottom, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr="rgba(153, 153, 153, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
}

div.dt-buttons > .dt-button:focus:not(.disabled),
div.dt-buttons > div.dt-button-split .dt-button:focus:not(.disabled) {
	outline: 2px solid rgb(53, 132, 228);
}

div.dt-buttons > .dt-button embed,
div.dt-buttons > div.dt-button-split .dt-button embed {
	outline: none;
}

div.dt-buttons > div.dt-button-split .dt-button:first-child {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

div.dt-buttons > div.dt-button-split .dt-button:first-child:hover {
	border-right: 1px solid #666;
}

div.dt-buttons > div.dt-button-split .dt-button:last-child {
	border-left: 1px solid transparent;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

div.dt-buttons > div.dt-button-split .dt-button:last-child:hover {
	border-left: 1px solid #666;
}

div.dt-buttons span.dt-button-down-arrow {
	position: relative;
	top: -2px;
	font-size: 10px;
	padding-left: 10px;
	line-height: 1em;
	opacity: 0.6;
}

div.dt-buttons div.dt-button-split {
	display: inline-block;
}

div.dt-buttons div.dt-button-split .dt-button:first-child {
	margin-right: 0;
}

div.dt-buttons div.dt-button-split .dt-button:last-child {
	margin-left: -1px;
	padding-left: 0.75em;
	padding-right: 0.75em;
	z-index: 2;
}

div.dt-buttons div.dt-button-split .dt-button:last-child span {
	padding-left: 0;
}

div.dt-button-collection {
	position: absolute;
	top: 0;
	left: 0;
	width: 200px;
	margin-top: 3px;
	margin-bottom: 3px;
	padding: 0.75em 0;
	border: 1px solid rgba(0, 0, 0, 0.4);
	background-color: white;
	overflow: hidden;
	z-index: 2002;
	border-radius: 5px;
	box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
}

div.dt-button-collection .dt-button {
	position: relative;
	left: 0;
	right: 0;
	width: 100%;
	display: block;
	float: none;
	background: none;
	margin: 0;
	padding: 0.5em 1em;
	border: none;
	text-align: left;
	cursor: pointer;
	color: inherit;
}

div.dt-button-collection .dt-button.dt-button-active {
	background: none;
	box-shadow: none;
}

div.dt-button-collection .dt-button.disabled {
	cursor: default;
	opacity: 0.4;
}

div.dt-button-collection .dt-button:hover:not(.disabled) {
	border: none;
	background: rgba(153, 153, 153, 0.1);
	box-shadow: none;
}

div.dt-button-collection div.dt-button-split {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: stretch;
}

div.dt-button-collection div.dt-button-split button.dt-button {
	margin: 0;
	display: inline-block;
	width: 0;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 50px;
}

div.dt-button-collection div.dt-button-split button.dt-button-split-drop {
	min-width: 33px;
	flex: 0;
}

div.dt-button-collection.fixed .dt-button {
	border-radius: 0.25em;
	background: rgba(255, 255, 255, 0.1);
}

div.dt-button-collection.fixed {
	position: fixed;
	display: block;
	top: 50%;
	left: 50%;
	margin-left: -75px;
	border-radius: 5px;
	background-color: white;
	padding: 0.5em;
}

div.dt-button-collection.fixed.two-column {
	margin-left: -200px;
}

div.dt-button-collection.fixed.three-column {
	margin-left: -225px;
}

div.dt-button-collection.fixed.four-column {
	margin-left: -300px;
}

div.dt-button-collection.fixed.columns {
	margin-left: -409px;
}

@media screen and (max-width: 1024px) {
	div.dt-button-collection.fixed.columns {
		margin-left: -308px;
	}
}

@media screen and (max-width: 640px) {
	div.dt-button-collection.fixed.columns {
		margin-left: -203px;
	}
}

@media screen and (max-width: 460px) {
	div.dt-button-collection.fixed.columns {
		margin-left: -100px;
	}
}

div.dt-button-collection.fixed > :last-child {
	max-height: 100vh;
	overflow: auto;
}

div.dt-button-collection.two-column > :last-child,
div.dt-button-collection.three-column > :last-child,
div.dt-button-collection.four-column > :last-child {
	display: block !important;
	-webkit-column-gap: 8px;
	-moz-column-gap: 8px;
	-ms-column-gap: 8px;
	-o-column-gap: 8px;
	column-gap: 8px;
}

div.dt-button-collection.two-column > :last-child > *,
div.dt-button-collection.three-column > :last-child > *,
div.dt-button-collection.four-column > :last-child > * {
	-webkit-column-break-inside: avoid;
	break-inside: avoid;
}

div.dt-button-collection.two-column {
	width: 400px;
}

div.dt-button-collection.two-column > :last-child {
	padding-bottom: 1px;
	column-count: 2;
}

div.dt-button-collection.three-column {
	width: 450px;
}

div.dt-button-collection.three-column > :last-child {
	padding-bottom: 1px;
	column-count: 3;
}

div.dt-button-collection.four-column {
	width: 600px;
}

div.dt-button-collection.four-column > :last-child {
	padding-bottom: 1px;
	column-count: 4;
}

div.dt-button-collection .dt-button {
	border-radius: 0;
}

div.dt-button-collection.columns {
	width: auto;
}

div.dt-button-collection.columns > :last-child {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	gap: 6px;
	width: 818px;
	padding-bottom: 1px;
}

div.dt-button-collection.columns > :last-child .dt-button {
	min-width: 200px;
	flex: 0 1;
	margin: 0;
}

div.dt-button-collection.columns.dtb-b3 > :last-child,
div.dt-button-collection.columns.dtb-b2 > :last-child,
div.dt-button-collection.columns.dtb-b1 > :last-child {
	justify-content: space-between;
}

div.dt-button-collection.columns.dtb-b3 .dt-button {
	flex: 1 1 32%;
}

div.dt-button-collection.columns.dtb-b2 .dt-button {
	flex: 1 1 48%;
}

div.dt-button-collection.columns.dtb-b1 .dt-button {
	flex: 1 1 100%;
}

@media screen and (max-width: 1024px) {
	div.dt-button-collection.columns > :last-child {
		width: 612px;
	}
}

@media screen and (max-width: 640px) {
	div.dt-button-collection.columns > :last-child {
		width: 406px;
	}

	div.dt-button-collection.columns.dtb-b3 .dt-button {
		flex: 0 1 32%;
	}
}

@media screen and (max-width: 460px) {
	div.dt-button-collection.columns > :last-child {
		width: 200px;
	}
}

div.dt-button-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	background: radial-gradient(
		ellipse farthest-corner at center,
		rgba(0, 0, 0, 0.3) 0%,
		rgb(185 183 183 / 70%) 100%
	);
	z-index: 2001;
}

.dt-button.processing {
	color: rgba(0, 0, 0, 0.2);
}

.dt-button.processing:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 16px;
	height: 16px;
	margin: -8px 0 0 -8px;
	box-sizing: border-box;
	display: block;
	content: ' ';
	border: 2px solid rgb(40, 40, 40);
	border-radius: 50%;
	border-left-color: transparent;
	border-right-color: transparent;
	animation: dtb-spinner 1500ms infinite linear;
	-o-animation: dtb-spinner 1500ms infinite linear;
	-ms-animation: dtb-spinner 1500ms infinite linear;
	-webkit-animation: dtb-spinner 1500ms infinite linear;
	-moz-animation: dtb-spinner 1500ms infinite linear;
}

@media screen and (max-width: 640px) {
	div.dt-buttons {
		float: none !important;
		text-align: center;
	}
}

html.dark div.dt-buttons > .dt-button,
html.dark div.dt-buttons > div.dt-button-split .dt-button {
	border: 1px solid rgb(89, 91, 94);
	background: rgba(255, 255, 255, 0.15);
}

html.dark div.dt-buttons > .dt-button.dt-button-active:not(.disabled),
html.dark div.dt-buttons > div.dt-button-split .dt-button.dt-button-active:not(.disabled) {
	background: rgba(179, 179, 179, 0.15);
	box-shadow: inset 1px 1px 2px black;
}

html.dark div.dt-buttons > .dt-button.dt-button-active:not(.disabled):hover:not(.disabled),
html.dark
	div.dt-buttons
	> div.dt-button-split
	.dt-button.dt-button-active:not(.disabled):hover:not(.disabled) {
	background: rgba(128, 128, 128, 0.15);
	box-shadow: inset 1px 1px 3px black;
}

html.dark div.dt-buttons > .dt-button:hover:not(.disabled),
html.dark div.dt-buttons > div.dt-button-split .dt-button:hover:not(.disabled) {
	background: rgba(179, 179, 179, 0.15);
}

html.dark div.dt-buttons > .dt-button:focus:not(.disabled),
html.dark div.dt-buttons > div.dt-button-split .dt-button:focus:not(.disabled) {
	outline: 2px solid rgb(110, 168, 254);
}

html.dark div.dt-buttons > div.dt-button-split .dt-button:first-child {
	border-right: 1px solid rgba(255, 255, 255, 0.1);
}

html.dark div.dt-buttons > div.dt-button-split .dt-button:first-child:hover {
	border-right: 1px solid rgb(89, 91, 94);
}

html.dark div.dt-buttons > div.dt-button-split .dt-button:last-child:hover {
	border-left: 1px solid rgb(89, 91, 94);
}

html.dark div.dt-button-collection {
	border: 1px solid rgba(255, 255, 255, 0.15);
	background-color: rgb(33, 37, 41);
	box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.8);
}

.dataTables_scroll.dtfc-has-left {
	/* border: 1.5px solid #cacacc;
    border-radius: 5px; */
	padding: 0px;
}

a {
	text-decoration: none !important;
	color: #2d8cff !important;
}

a:hover {
	text-decoration: underline !important;
}

.table-width {
	width: 80% !important;
}

div.dataTables_length {
	margin-top: 0.755em;
}

.company-td {
	display: flex;
	align-items: center;
	width: 100%;
}

.company {
	display: flex;
	align-items: center;
	width: 100%;
}

.company.primary {
	padding: 10px 0px;
}

.company-td .c-td-span {
	box-shadow: 1px 1px 1px 0 #e3e3e5;
	border-radius: 4px;
	margin-right: 10px;
}

.company-td .c-name-title {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;
}

.company-td .c-name-title .c-name-title-div {
	display: flex;
	align-items: center;
}

.company-td .c-name-title .c-name-a {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #0852c2;
	display: block;
	text-decoration: none;
	text-align: left;
	margin-right: 10px;
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.table-icon {
	display: flex;
	flex-flow: row;
	align-items: center;
}

.table-icon > *:not(:last-child) {
	margin-right: 8px;
}

.icon-link {
	cursor: pointer;
	font-weight: 400;
	color: #146ef6;
	text-decoration: none;
}

.table-icon .svg-icon {
	font-size: 16px;
	color: #919191;
}

[class^='bootstrape-icon-'],
[class*=' bootstrape-icon-'],
.bootstrape-colored-icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

[class^='bootstrape-icon-']:before,
[class*=' bootstrape-icon-']:before {
	font: normal normal normal 24px/1;
	font-size: inherit;
	line-height: inherit;
	text-rendering: auto;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.action-btn {
	border: 1px solid #b9b9b9;
	border-radius: 5px;
	padding: 3px 11px;
}

.action-btn-style {
	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
	line-height: 1;
	white-space: nowrap;
}

td.dtfc-fixed-left {
	flex-direction: column;
	box-shadow: 3px 0 3px rgba(0, 0, 0, 0.07) !important;
}

td {
	box-shadow: none !important;
}

.dt-buttons:hover {
	cursor: pointer;
}

table.dataTable.stripe > tbody > tr.even.selected > *,
table.dataTable.display > tbody > tr.even.selected > * {
	color: #000000 !important;
	flex-direction: column;
	box-shadow: 3px 0 3px rgba(0, 0, 0, 0.07) !important;
}

table.dataTable.row-border > tbody > tr.selected + tr.selected > td,
table.dataTable.display > tbody > tr.selected + tr.selected > td {
	border-top-color: #d9d9d9 !important;
}

button.paginate_button.previous {
	border: 1px solid #cacacc !important;
	padding-right: 8px !important;
	padding-left: 8px !important;
	border-radius: 2px !important;
	background: #ffffff !important;
	padding: 0em 0em !important;
	margin-right: 2px;
	font-weight: 600;
	font-size: 20px;
}

button.paginate_button.next {
	border: 1px solid #cacacc !important;
	padding-right: 8px !important;
	padding-left: 8px !important;
	border-radius: 2px !important;
	background: #ffffff !important;
	padding: 0em 0em !important;
	font-weight: 600;
	font-size: 20px;
}

select {
	border: 1px solid #cacacc;
	padding: 3px;
	padding-top: 5px;
	padding-bottom: 2px;
}

input[type='checkbox']::after {
	/* appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	border-radius: 3px;
	border: 1px solid black;
	width: 16px;
	height: 16px;
	background: white !important;
	box-shadow: none !important;
    box-sizing: border-box;
	line-height: 13.9px !important;
	margin-top: 11px;
    margin-left: 5px; */
	height: 16px;
	width: 16px;
	margin-right: 0px;
	border-radius: 3px;
	margin-left: 5px;
}

input[type='checkbox']:checked:before {
	/* content: "✓";
	font-size: 15px;
	margin-top: -9px;
	text-align: center;
	font-weight: 700; */
}

.loader-0 {
	height: 2px !important;
	border: none !important;
	padding: 0px !important;
}

/* loader css */

th,
td {
	white-space: nowrap;
}
div.dataTables_wrapper {
	/* width: 800px; */
	margin: 0 auto;
	/* margin-top: -8px; */
}

.animated {
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
}

.yt-loader {
	-webkit-animation-name: horizontalProgressBar;
	animation-name: horizontalProgressBar;
	-webkit-animation-timing-function: ease;
	animation-timing-function: ease;
	background: #2d8cff;
	height: 3px;
	left: 0;
	top: 0;
	width: 0%;
	z-index: 9999;
	position: relative;
}

.yt-loader:after {
	display: block;
	position: absolute;
	content: '';
	right: 0px;
	width: 100px;
	height: 100%;
	/* box-shadow: #ef534e 1px 0 6px 1px; */
	opacity: 0.5;
}
@keyframes horizontalProgressBar {
	0% {
		width: 0%;
	}
	20% {
		width: 10%;
	}
	30% {
		width: 15%;
	}
	40% {
		width: 18%;
	}
	50% {
		width: 20%;
	}
	60% {
		width: 22%;
	}
	100% {
		width: 100%;
	}
}